<template>
  <el-container class="xa-container upload-page" direction="vertical">
    <titleFilterBarHead title="批量上传图片" :showSwitchBtn="false" />
    <div class="upload__wrap xa-bg-white">
      <form ref="form" action="" class="xa-cell">
        <label class="upload__item upload__item--add">
          <i class="el-icon-plus avatar-uploader-icon"></i>
          <input
            @change="onFileAdd"
            class="upload__input"
            type="file"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            multiple="true"
          />
        </label>
        <div
          ref="dropArea"
          class="upload__drag"
          :class="isDraging ? 'upload__drag--draging' : ''"
          @drop.stop="onDragEvent"
          @dragover.stop="onDragEvent"
          @dragleave.stop="onDragEvent"
        >
          <span>点击+号选择上传或将图片拖到此处</span>
        </div>
      </form>
    </div>
    <el-main direction="vertical" class="upload__wrap xa-bg-white">
      <div class="xa-container">
        <div class="upload__container">
          <div
            class="upload__box xa-cell"
            v-for="item in fileList"
            :key="item.uuid"
          >
            <div class="upload__item">
              <i class="el-icon-loading" v-if="item.status === 'init'"></i>
              <img class="upload__img" :src="item.src" alt="" srcset="" />
            </div>
            <div class="upload__content xa-txt-12 xa-color-info">
              <div class="upload__item-name">{{ item.name }}</div>
              <div class="xa-cell">
                {{ item.size }}
                <span
                  v-if="item.status === 'uploadSccuess'"
                  class="upload__btn xa-color-success xa-txt-18 el-icon-success"
                ></span>
                <span
                  v-else
                  @click="onDelete(item)"
                  class="upload__btn xa-color-danger xa-txt-18 el-icon-delete"
                ></span>
              </div>
            </div>
            <div
              class="upload__progress xa-cell xa-flex-center"
              v-if="item.status === 'upload'"
            >
              <el-progress
                type="circle"
                :percentage="item.progress"
                :width="68"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <footer class="xa-cell" style="padding: 4px 0">
      <div class="xa-flex"></div>
      <div class="xa-mgr-16 xa-txt-12 xa-color-info">
        {{ fileList.length }}张,{{ sccuessList.length }}上传成功
      </div>
      <el-button
        size="small"
        type="primary"
        :disabled="fileList.length === 0"
        plain
        @click="onUploadFile"
        >点击上传</el-button
      >
    </footer>
  </el-container>
</template>
<script>
import * as UUID from 'uuidjs'
import { qiniuUpload } from '@/controllers/upload.js'
import { setTimeout } from 'timers'
const FileStatus = [
  'init',
  'load',
  'upload',
  'uploading',
  'uploadSccuess',
  'uploadFail'
]
class PromiseTaskQueue {
  constructor(max = 2) {
    this.inWork = false
    this.list = []
    this.workers = 0
    this.max = max
  }
  add(task) {
    this.list.push(task)
  }
  run() {
    if (this.inWork) return
    this.inWork = true
    this._run()
  }
  _run() {
    const promiseTask = this.list.shift()
    if (promiseTask) {
      this.workers++
      promiseTask().then(() => {
        this.workers--
        setTimeout(() => this._run(), 0)
      })
      if (this.workers < this.max) this._run()
    } else {
      if (this.workers === 0) this.inWork = false
    }
  }
}
export default {
  components: {
    titleFilterBarHead: () => import('@/components/comHead/FilterBarHead')
  },
  data() {
    return {
      isDraging: false,
      fileList: [],
      displayImgTask: Object.create(new PromiseTaskQueue(3)),
      upladFileTask: Object.create(new PromiseTaskQueue(2))
    }
  },
  computed: {
    sccuessList() {
      return this.fileList.filter(item => item.status === FileStatus[4])
    }
  },
  methods: {
    onUploadFile() {
      const waitToUploadList = this.fileList.filter(item => {
        const index = FileStatus.indexOf(item.status)
        return index < 2
      })
      waitToUploadList.forEach(item =>
        this.upladFileTask.add(this.handleUploadFile.bind(this, item))
      )
      this.upladFileTask.run()
    },
    handleUploadFile(fileItem) {
      fileItem.status = FileStatus[2]
      return new Promise(resolve => {
        qiniuUpload({
          file: fileItem.file,
          progress: progress => {
            fileItem.progress = parseInt(progress.total.percent)
          }
        })
          .then(data => {
            fileItem.status = FileStatus[4]
            Object.assign(fileItem, data)
            resolve()
          })
          .catch(() => {
            fileItem.status = FileStatus[5]
            resolve()
          })
      })
    },
    onFileAdd(e) {
      this.handleAddFile(e.target.files)
    },
    onDelete(item) {
      this.fileList.splice(this.fileList.indexOf(item), 1)
      this.$refs.form.reset()
      item.uuid = null
    },
    onDragEvent(e) {
      e = e || window.event
      e.stopPropagation()
      e.preventDefault()
      this.isDraging = e.type === 'dragover'
      if (e.type === 'drop' && e.dataTransfer.files) {
        this.handleAddFile(e.dataTransfer.files)
      }
    },
    displayImg(file, item) {
      return new Promise(resolve => {
        const reader = new window.FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = e => {
          resolve()
          if (item.uuid) {
            item.src = e.target.result
            item.status = FileStatus[1]
          }
        }
      })
    },
    handleAddFile(mFileList) {
      for (let i = 0; i < mFileList.length; i++) {
        const uuid = UUID.generate()
        const item = {
          uuid,
          file: mFileList[i],
          name: mFileList[i].name,
          size: this.$utils.converFileSize(mFileList[i].size),
          src: '',
          status: FileStatus[0],
          progress: 0
        }
        this.fileList.push(item)
        this.displayImgTask.add(this.displayImg.bind(this, mFileList[i], item))
        this.displayImgTask.run()
      }
    }
  }
}
</script>
<style lang="scss">
$size: 120px;

.upload {
  &__wrap {
    padding: 8px;
    margin: 4px 0;
    width: 100%;
    /deep/ .el-progress__text {
      color: #fff;
    }
  }
  &__container {
    margin: 8px 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__box {
    position: relative;
    margin: 0 8px 8px 0;
    border: 1px solid $color-primary;
    border-radius: 6px;
  }
  &__progress {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
  &__item {
    margin-right: 8px;
    display: block;
    position: relative;
    width: $size - 20;
    height: $size - 20;
    color: #8c939d;
    line-height: $size - 20;
    overflow: hidden;
    text-align: center;
  }
  &__item-name {
    line-height: 2;
    width: $size;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &__item--add {
    margin: 0;
    width: $size;
    height: $size;
    border-radius: 6px;
    border: 1px dashed currentColor;
    cursor: pointer;
    text-align: center;
    line-height: $size;
    font-size: 28px;
    &:hover {
      font-size: 32px;
      color: $color-primary;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    margin-right: 8px;
  }
  &__drag {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    width: 3 * $size;
    height: $size;
    border: 1px dashed currentColor;
    color: #8c939d;
    border-radius: 6px;
    &--draging {
      box-shadow: 0px 0px 4px inset $color-primary;
    }
  }
  &__input {
    position: absolute;
    opacity: 0;
  }
  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__btn {
    margin-left: auto;
    cursor: pointer;
  }
}
</style>
